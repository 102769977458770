.bg-head {
  background-image: url("./../../img/bghead.svg");
  background-color: rgb(10, 10, 10);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
}
@media (max-width: 1024px) {
  .bg-head {
    height: auto;
  }
  .height-tablet {
    min-height: 200px;
  }
}

@media (min-width: 641px) and (max-width: 768px) {
  .height-tablet {
    min-height: 200px;
  }
  .bg-head {
    height: auto;
  }
  .bg-earn {
    background-image: url("./../../img/startearn-md.svg");
  }
}
@media (max-width: 640px) {
  .height-tablet {
    min-height: 0 !important;
  }
  .bg-head {
    height: auto;
    background-image: url("./../../img/bghead-mb.svg");
  }
  .logo-img {
    width: 60px;
  }
  .bg-head.bg-head-bot {
    background-image: url("./../../img/bghead-mbbot.svg");
  }
}
.bg-head.bg-head-bot {
  height: 50vh;
  background-image: url("./../../img/bghead-bot.svg");
}
.bg-earn {
  background-image: url("./../../img/startearn.svg");
  background-color: rgb(10, 10, 10);
  background-size: cover;
  /* background-size: auto; */
  background-repeat: no-repeat;
  background-position: center;
  /* height: 70vh; */
  min-width: 100%;
}
.bg-earn-mb {
  background-image: url("./../../img/startearn-mb.svg");
  /* background-image: url("./../../img/startearn-mb.svg"); */
  background-color: rgb(10, 10, 10);
  background-size: cover;
  /* background-size: auto; */
  background-repeat: no-repeat;
  background-position: center;
  /* height: 70vh; */
  min-width: 100%;
}
.bg-item-first {
  background-image: url("./../../img/x100.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 70vh; */
  width: 100%;
}
.bg-item-second {
  background-image: url("./../../img/rocketfree.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* height: 70vh; */
  width: 100%;
}
.header-mobi {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
}
.header-mobi.active {
  transform: translateY(0);
  transition: transform 0.5s ease-in-out;
}
